.petstar {
  max-width: 1440px;
  padding: 0;
  padding-top: 0rem;
  box-shadow: 0 0 5px lightgrey;
  margin-bottom: 0;
}
.petstar > div {
  margin: 0 auto;
  margin-bottom: 4rem;
}
.petstar > h1 {
  color: var(--default-blue);
  font-size: 2rem;
}
.petstar h2 {
  color: var(--default-orange);
  font-size: 2rem;
  text-align: center;
}

.petstar h4 {
  text-align: center;
}

.petstar > h2 {
  padding: 0 3rem;
}

.petstar_header {
  width: 100%;
  height: 49rem;
  display: flex;
  gap: 0rem;
}
.petstar_header > div {
  width: 1rem;
  flex: 1;
  padding: 2rem;
}
.petstar_header h1 {
  color: var(--default-blue);
  font-size: 3rem;
}
.petstar_header h1 > svg {
  color: var(--default-orange);
}

.petstar_header > img {
  width: 1rem;
  flex: 1.25;
  height: 100%;
  object-fit: cover;
  object-position: 80% 50%;
}
.subscription_button {
  width: 10rem;
  height: 7rem;
  border: 2px solid var(--default-orange);
  border-radius: 16px;
  box-shadow: 0 4px 4px lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* Adjust as necessary */
  color: black; /* Adjust text color as necessary */
  font-size: 1.25rem; /* Adjust font size as necessary */
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.subscription_button:hover {
  box-shadow: 0 4px 4px darkgrey;
}

.petstar_donation_amounts_desktop {
  width: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.petstar_donation_amounts_desktop > div {
  width: 10rem;
  height: 7rem;
  border: 2px solid var(--default-orange);
  border-radius: 16px;
  box-shadow: 0 4px 4px lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}
.petstar_donation_amounts_desktop > div:hover {
  box-shadow: 0 4px 4px darkgrey;
}

.petstar_donation_amounts_mobile {
  width: 32rem;
  margin: 0 auto;
  display: none;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.petstar_donation_amounts_mobile > div {
  width: 10rem;
  height: 7rem;
  border: 2px solid var(--default-orange);
  border-radius: 16px;
  box-shadow: 0 4px 4px lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}
.petstar_donation_amounts_mobile > div:hover {
  box-shadow: 0 4px 4px darkgrey;
}

.petstar_what {
  display: flex;
  gap: 2rem;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  justify-content: center;
}
.petstar_what > div {
  flex: 1 1 0rem;
}
.petstar_what > img {
  width: 34rem;
  height: 34rem;
  object-fit: contain;
}

.petstar_column_points {
  display: flex;
  gap: 0;
  padding: 0 3rem;
  justify-items: center;
  align-items: center;
}
.petstar_column_points > svg {
  font-size: 4rem;
  color: var(--default-orange);
  padding-right: 1rem;
  padding-left: 1rem;
}
.petstar_column_points > div {
  flex: 1;
}

.petstar_column_points > div > p {
  text-align: center;
  font-size: 1.2rem;
}

.petstar_video > iframe {
  border-radius: 16px;
}

.petstar_updates {
  display: flex;
  gap: 4rem;
  padding: 0 1rem;
  align-items: center;
}
.petstar_updates > div {
  flex: 1 1 0rem;
  text-align: left;
}
.petstar_updates img {
  width: 25rem;
  height: 25rem;
  object-fit: contain;
}
.petstar_updates ul {
  list-style: none;
  padding-left: 1rem;
}
.petstar_updates li {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}
.petstar_updates li > p {
  margin: 0;
}
.petstar li svg {
  color: darkgrey;
}

.petstar_ready a {
  font-size: 1.5rem;
  display: inline-flex;
  text-decoration: none;
  background-color: var(--default-orange);
  color: white;
  border: none;
  border-radius: 20px;
  height: 3rem;
  width: 12rem;
  align-items: center;
  justify-content: center;
}

.mobile-donation {
  display: none !important;
}

/* reponsive media queries */
@media (max-width: 768px) {
  .subscription_button {
    width: 7rem;
    height: 6rem;
    font-size: 18px;
  }

  .petstar_column_points {
    flex-direction: column;
  }

  .petstar_donation_amounts_desktop {
    display: none;
  }

  .petstar_donation_amounts_mobile {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 115px);
    gap: 10px;
    justify-items: center;
  }
  .petstar_donation_amounts_mobile > div {
    margin: 0 auto;
    height: 4rem;
  }
  .petstar_header {
    flex-direction: column-reverse;
    height: 21rem;
    justify-content: flex-end;
    position: relative;
    padding-top: 3.5rem;
  }
  .petstar_header::before {
    content: ""; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 23rem; 
    background-color: rgba(0, 0, 0, 0.4); 
    z-index: 0; 
  }
  .petstar_header > div{
    flex: 0;
    width: 100%;
    z-index: 0;
  }
  .petstar_header > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 23rem;
    z-index: -1;
  }
  
  .petstar_header > div > h1 {
    font-size: 2rem;
  }
  .petstar_header > div > p {
    padding: 0;
  }

  .petstar_what > img {
    height: auto;
    width: 100%;
  }
  .petstar_video {
    width: 100%;
    padding: 1rem;
    height: fit-content;
  }
  .petstar_video > iframe {
    width: 100%;
    height: auto;
  }
  .petstar_updates {
    flex-direction: column;
  }
  .petstar_updates_reverse {
    flex-direction: column-reverse;
  }
  .petstar_updates > img {
    width: 100%;
    height: auto;
  }
  .icons{
    transform: rotate(90deg);
    margin-top: 20px;
    margin-bottom: 20px; 
  }

}