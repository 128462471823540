.faq {
  margin: 0 auto;
  max-width: 1440px;
  padding: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.body_div {
  max-width: var(--page-width);
  margin: 1rem auto 0 auto;
}

.faq_title {
  text-align: center;
  margin-bottom: 1rem;
}

.faq_title > h1 {
  font-family: "Finger Paint", cursive;
  font-size: 2.5rem;
  color: var(--default-orange);
  text-align: center;
  margin-bottom: 0;
}

.faq_nav {
  flex: 0 0 12rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.faq_nav_item,
.faq_nav_item:visited {
  width: 100%;
  text-align: left;
  padding: 0.6rem 0;
  text-decoration: none;
  color: var(--default-blue);
  cursor: pointer;
}

.faq_content {
  flex: 1;
  text-align: left;
}

.faq_content > hr {
  margin-bottom: 2rem;
}

.faq_content > h1 {
  margin-top: 0;
}

.faq_content_columns {
  display: flex;
  gap: 32px;
  margin-top: 24px;
}

.content_hr {
  margin: 48px 0;
}

.faq_content > h3 {
  color: var(--default-blue);
}

.faq_content > h2 {
  color: var(--dark-grey);
}

#rescue,
#donations,
#why-sponsor-a-pet,
#fundraising,
#for-donors,
#for-nonprofits {
  scroll-margin-top: 7rem;
}

.mobile_hr {
  display: none;
}

@media (max-width: 768px) {
  .body_div {
    max-width: var(--mobile-page-width);
  }
  .faq_title > h1 {
    font-size: 2rem;
  }
  .faq {
    flex-direction: column;
    padding: 16px;
  }
  .faq_content_columns {
    flex-direction: column;
  }
  .mobile_hr {
    display: block !important;
    border: none !important;
    border-top: 1px solid #333 !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
