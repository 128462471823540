.login_signup_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    overflow: auto;
    padding: 50px;
}

.modal {
    width: 500px;
}

.xButton{
    font-size: 1.875rem;
}

@media (max-width: 1280px) {
    .modal_content {
        height: 100%;
    }
}

@media (max-width: 1024px) {
    .modal_content {
        height: 100%;
    }
}

@media (max-width: 430px) {
    .modal_content {
        width: 100%;
    }

    .modal {
        width: 100%;
    }
}