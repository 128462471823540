html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root,
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.main-div {
  flex: 1; /* Allows .main-div to expand and take up any available space */
  width: 100%;
  padding-top: 0;
  margin: 6rem auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.App h1 {
  font-family: "Finger Paint", cursive;
  font-size: xx-large;
  font-weight: bold;
}

.App p {
  font-size: 18px;
}
