.top_section {
  height: 60vw;
  background: var(--default-blue);
  overflow: clip;
  position: relative;
  z-index: 0;
}

.text_box {
  max-width: 637px;
  width: 100%;
  height: auto;
  padding: 10px;
  gap: 1.5rem;
  top: 20%;
  left: 12%;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.header_text {
  max-width: 617px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_box h1 {
  font-family: Finger Paint;
  font-size: 4rem;
  font-weight: 400;
  line-height: 1.2;
}

.text_box .paragraph {
  max-width: 444px;
  font-family: Inter;
  line-height: 1.3;
  text-align: left;
}

.first_text {
  font-size: 1rem;
  font-weight: 700;
  float: left;
}

.second_text {
  font-size: 1rem;
  font-weight: 400;
}

.top_section img {
  right: 15%;
  top: 10%;
  max-width: 327px;
  max-height: 444px;
  transform: rotateY(-180deg);
  filter: drop-shadow(-49px 16px 19px rgba(0, 0, 0, 0.37));
}

.text_box button {
  max-width: 199px;
  max-height: 51px;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: var(--default-orange);
  padding: 16px 52px;
  gap: 10px;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
}

.gradient {
  right: 0;
  width: 29%;
  height: 100%;
  border-radius: 50px 0 0 0;
  background: linear-gradient(
    71.47deg,
    #fb4ffe 22.92%,
    #d8ecf8 62.85%,
    #46a4dd 96.68%
  );
  position: absolute;
  z-index: 10;
}

.circle_1 {
  max-width: 604px;
  max-height: 644.09px;
  width: 100%;
  height: 100%;
  top: 6%;
  right: 7%;
  background: linear-gradient(
    142.13deg,
    rgba(255, 255, 255, 0.58) 1.8%,
    rgba(255, 255, 255, 0.66) 99.75%
  );
  border-radius: 50%;
  position: absolute;
  z-index: 20;
}

.circle_2 {
  max-width: 490px;
  max-height: 522.32px;
  width: 100%;
  height: 100%;
  top: 14%;
  right: 10%;
  background: linear-gradient(
    142.13deg,
    rgba(255, 255, 255, 0.58) 1.8%,
    rgba(255, 255, 255, 0.66) 99.75%
  );
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  z-index: 30;
}

.circle_3 {
  max-width: 389px;
  max-height: 414.44px;
  width: 100%;
  height: 100%;
  top: 21.5%;
  right: 12.5%;
  background: linear-gradient(
    142.13deg,
    rgba(255, 255, 255, 0.58) 1.8%,
    rgba(255, 255, 255, 0.66) 99.75%
  );
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  z-index: 40;
}

.dog_img {
  position: absolute;
  z-index: 50;
}
.why_partner_section {
  height: auto;
  padding-bottom: 4%;
}

.bar_header {
  margin: 8% auto 5% auto;
}

.subheader {
  width: 48vw;
  height: 100%;
  background-color: #c569ba;
  box-shadow: 6px 2px 4px 0px #00000040;
  border-radius: 78px;
  padding: 1.5% 0 0 10%;
}

@media (max-width: 600px) {
  .bar_header {
    height: 200px;
  }

  .subheader {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subheader p {
    font-size: 1rem;
  }
}

.subheader p {
  font-family: Finger Paint;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.065em;
}

.bar_header img {
  width: 13%;
  height: auto;
  bottom: 8%;
  left: 2%;
}

.reasons_section {
  margin: 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px) {
  .reasons_section {
    flex-direction: column;
  }
}

.reasons svg {
  margin: 0 auto 10% auto;
}

.texts {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
  max-width: 280px;
}

.subtitle {
  width: 100%;
  height: 56px;
  font-family: Inter;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.3;
  text-align: left;
  color: #121127;
}

.description {
  width: 100%;
  height: 150px;
  font-family: Inter;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  color: #1211278f;
}

.banner {
  width: 100%;
  height: 244px;
  background-color: #c569ba;
  overflow: clip;
}

.banner_title {
  font-family: Finger Paint;
  font-size: 2rem;
  font-weight: 400;
  line-height: 44.5px;
  text-align: left;
  color: #ffffff;
  text-align: center;
  margin-top: 2.5%;
}

@media (max-width: 600px) {
  .banner_title {
    margin-bottom: -2rem;
  }
}

.paw {
  top: -62%;
  left: 50%;
  transform: translateX(-50%);
}

.steps_section {
  width: 100%;
}

@media (max-width: 600px) {
  .steps_section {
    margin-top: 3rem;
  }
}

.header {
  margin: 4% 0 0 0;
}

.header p {
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--default-blue);
  margin-bottom: 1.5%;
}

.header h3 {
  color: #1c1c1c;
  font-family: Finger Paint;
  font-weight: 400;
  line-height: 1.5;
  font-size: 30px;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.step_bottom {
  align-items: center;
  justify-content: space-around;
  padding-left: 10%;
}

.step_bottom img {
  width: 25vw;
}

.horizontal_count {
  margin-bottom: 4%;
  display: block;
}

.vertical_count {
  display: none;
}

.steps {
  gap: 4.3rem;
}

.step {
  width: 19.5%;
  height: 25%;
}

.step h4 {
  font-family: Inter;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.3;
  text-align: left;
  color: #010101;
  margin-bottom: 4%;
}

.step p {
  font-family: Inter;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  color: #717171;
  font-size: 1.3rem;
}

.community_section {
  width: 100%;
  padding-bottom: 10%;
}

.community_section h2 {
  font-family: Finger Paint;
  font-size: 4rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 5% 0;
}

.animal_lovers {
  gap: 3rem;
}

.animal_block img {
  width: 100%;
  max-width: 402px;
  aspect-ratio: 402 / 456;
  border-radius: 25px;
  object-fit: cover;
  margin-bottom: 5%;
}

.user {
  gap: 5rem;
  font-family: Inter;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
}

.user p {
  font-weight: 700;
}

.username {
  color: var(--default-blue);
}

.banner_2 {
  width: 100%;
  height: 245px;
  background-color: #c569ba;
  align-items: center;
  overflow-x: clip;
  justify-content: flex-start;
}
.banner_2 h2 {
  font-family: Finger Paint;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 8%;
}

.banner_2 img {
  width: 384px;
  height: auto;
  top: -30%;
  right: 5%;
}

.review_section {
  width: 100%;
  padding-bottom: 2%;
}

.carousel {
  margin-top: 5%;
  margin: 5% 0;
}

.review {
  gap: 5rem;
}

.img_section img {
  width: 400px;
  height: 480px;
  border-radius: 20px;
  bottom: 29.5%;
  left: 21.5%;
}

.text_section {
  font-family: Inter;
  font-weight: 500;
  line-height: 1.5;
  justify-content: center;
  gap: 2%;
  align-items: flex-start;
}

.text_section p {
  max-width: 600px;
  height: auto;
  font-size: 1.8rem;
  color: #14131a;
  margin: 5% 0 10% 0;
}

.reviewer_name {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  text-align: left;
  color: #010101;
}

.reviewer_title {
  color: #717171;
}

.metrics {
  gap: 10%;
}

.metric {
  font-family: Inter;
}

.metric p {
  font-size: 1.5;
  font-weight: 500;
  line-height: 1;
  color: #262b34;
}

.metric p span {
  color: var(--default-blue);
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2;
}

.slide_dots {
  margin-top: 2%;
  justify-content: center;
  align-items: center;
}

.new_img {
  position: absolute;
  z-index: 30;
}

.contact_section {
  width: 100%;
}

.contact_section img {
  width: 100%;
  height: 718.5px;
  object-fit: cover;
}

@media (max-width: 600px) {
  .slide_dots {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .why_button {
    padding-right: 8;
  }
  .new_img {
    position: static;
  }
  .contact_section img {
    height: 500px;
  }
}

.contact_us {
  font-family: Inter;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact_us p {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--default-blue);
}

.contact_us h2 {
  font-family: Finger Paint;
  font-size: 5rem;
  font-weight: 400;
  line-height: 2;
  text-align: center;
  margin-bottom: 13%;
}

.contact_section svg {
  right: 25%;
  bottom: 8%;
}

@media (max-width: 1536px) {
  .text_box {
    left: 10%;
  }

  .top_section img {
    right: 16%;
    top: 10%;
    width: 50vw;
    height: auto;
  }

  .circle_1 {
    top: 6%;
    right: 7%;
  }

  .circle_2 {
    top: 14%;
    right: 10.5%;
  }

  .circle_3 {
    top: 21.5%;
    right: 13.5%;
  }

  .subheader {
    width: 60vw;
    height: 100%;
    padding-left: 50px;
  }

  .step_bottom {
    padding-left: 8%;
  }

  .step_bottom img {
    width: 30vw;
  }
  .steps {
    gap: 5rem;
  }

  .step {
    width: 25%;
  }

  .animal_lovers {
    gap: 0;
  }

  .banner_2 img {
    right: 2%;
  }

  .carousel {
    margin: 5% 0 2% 0;
  }

  .review {
    gap: 5rem;
  }

  .img_section img {
    bottom: 25.5%;
    left: 15%;
  }

  .contact_section svg {
    right: 23%;
  }
}

@media (max-width: 1400px) {
  .subheader {
    width: 45vw;
  }
}

@media (max-width: 1200px) {
  .text_box {
    left: 5%;
  }

  .top_section img {
    right: 2%;
  }

  .circle_1 {
    top: 6%;
    right: -9%;
    width: 85%;
    height: 85%;
  }

  .circle_2 {
    top: 12.5%;
    right: -4%;
    width: 85%;
    height: 85%;
  }

  .circle_3 {
    top: 20%;
    right: 0;
    width: 85%;
    height: 85%;
  }

  .subheader {
    width: 60vw;
    /* width: 50vw;
    height: 2vh;
    background-color: #c569ba;
    box-shadow: 6px 2px 4px 0px #00000040;
    border-radius: 78px;
    padding: 1.8% 0 0 10%; */
  }

  /* .subheader p {
    font-size: 1.5rem;
  } */

  .reasons_section {
    margin: 0 5%;
    gap: 2rem;
  }

  .banner_title {
    font-size: 1.8rem;
    margin-top: 3%;
  }

  .step_bottom {
    padding: 0 1rem 0 0;
    gap: 0;
  }

  .step_bottom img {
    width: 45vw;
  }

  .horizontal_count svg {
    width: 45vw;
  }

  .steps {
    gap: 2rem;
  }

  .step {
    width: 35%;
  }

  .animal_lovers {
    gap: 0;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    row-gap: 50px;
  }

  .animal_block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-basis: 50%;
  }

  .user {
    gap: 5rem;
  }

  .banner_2 h2 {
    width: 55%;
    font-size: 2.5rem;
    margin-left: 8%;
  }

  .banner_2 img {
    width: 384px;
    height: auto;
    top: -30%;
    right: 0;
  }

  .review {
    gap: 2rem;
    margin: 0 5%;
  }

  .img_section img {
    bottom: 25%;
    left: 8.5%;
  }

  .text_section p {
    text-align: left;
  }

  .contact_us {
    bottom: 28%;
  }

  .contact_us h2 {
    width: 100vw;
    margin-bottom: 15%;
  }

  .contact_section svg {
    width: 30vw;
    right: 20%;
    bottom: 15%;
  }
}

@media (max-width: 900px) {
  .top_section {
    height: 60vw;
    max-height: 1000px;
  }

  .text_box {
    left: 2%;
    top: 5%;
    width: 50%;
  }

  .text_box h1 {
    font-size: 3rem;
  }

  .text_box .paragraph {
    width: 80%;
  }

  .top_section img {
    right: 13%;
    top: 14%;
    width: 25vw;
    height: auto;
  }

  .circle_1 {
    top: 10%;
    right: 0%;
    width: 50%;
    height: 85%;
  }

  .circle_2 {
    top: 18%;
    right: 5%;
    width: 40%;
    height: 70%;
  }

  .circle_3 {
    top: 28%;
    right: 9.5%;
    width: 30%;
    height: 50%;
  }

  .why_partner_section {
    padding-bottom: 15%;
  }

  @media (max-width: 600px) {
    .why_partner_section {
      margin-top: 3rem;
    }

    .reasons_section {
      margin-top: 3rem;
    }
  }

  .bar_header {
    margin-top: 12%;
  }

  .bar_header img {
    width: 15%;
    bottom: 8%;
  }

  .texts {
    gap: 45px;
  }

  .banner_title {
    font-size: 1.5rem;
    margin-top: 3%;
  }

  .step_bottom {
    margin-top: 5%;
  }

  .step_bottom img {
    margin-top: 45%;
  }

  .count_steps {
    flex-direction: row;
  }

  .horizontal_count {
    display: none;
  }

  .vertical_count {
    display: block;
    margin-right: 5%;
    height: 50%;
  }

  .steps {
    gap: 10.5rem;
    flex-direction: column;
  }

  .step {
    width: 95%;
    height: 20%;
  }

  .banner_2 img {
    right: -6%;
  }

  .img_section img {
    bottom: 27%;
    left: 10%;
  }

  .metrics {
    gap: 5%;
  }

  .contact_us {
    bottom: 30%;
  }

  .contact_section svg {
    right: 10%;
  }
}

@media (max-width: 800px) {
  .top_section {
    height: 70vw;
  }
}

@media (max-width: 600px) {
  .text_box {
    width: 50%;
    gap: 1rem;
  }

  .text_box h1 {
    font-size: 2rem;
  }

  .text_box .paragraph {
    width: 85%;
  }

  .top_section img {
    right: 13%;
  }

  .bar_header {
    margin-top: 15%;
  }

  .subheader {
    width: 70vw;
  }

  .bar_header img {
    width: 20%;
    height: auto;
    bottom: 49%;
    left: 2%;
  }

  .reasons_section {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .reasons {
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    row-gap: 20px;
  }

  .reasons svg {
    margin-bottom: 1%;
  }

  .texts {
    gap: 30px;
  }

  .step_bottom img {
    width: 35vw;
    margin-top: 100%;
    margin-left: 1rem;
  }

  .step_bottom {
    margin-bottom: 3rem;
    margin-left: 1rem;
  }

  .steps {
    gap: 8rem;
  }

  .animal_lovers {
    display: flex;
    flex-direction: column;
  }

  .animal_block img {
    max-width: 375px;
    aspect-ratio: 402 / 456;
  }

  .user {
    gap: 1rem;
  }

  .community_section {
    padding-bottom: 18%;
  }

  .banner_2 {
    justify-content: center;
    margin-bottom: 3rem;
  }

  .banner_2 h2 {
    font-size: 2rem;
    width: 50%;
    text-align: center;
    padding: 1rem 0;
  }

  .banner_2 img {
    right: -13%;
  }

  .review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .img_section img {
    bottom: 50.5%;
    left: 17%;
  }

  .metrics {
    gap: 2%;
  }

  .metric p span {
    font-size: 1.5rem;
  }

  .contact_us h2 {
    line-height: 1.3;
    font-size: 40px;
  }

  .top_section {
    height: 400vw;
  }
}

@media (max-width: 600px) {
  .subheader {
    width: 90vw;
  }
}

@media (max-width: 500px) {
  .top_section {
    width: 100%;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }

  .dog_image img {
    position: relative;
    margin-bottom: 6rem;
    height: 65%;
    width: 50%;
    left: 5rem;
  }

  .gradient {
    width: 75%;
  }

  .circle_1 {
    top: 50%;
    right: 20%;
    width: 60%;
    height: 45%;
    border-radius: 100%;
  }

  .circle_2 {
    top: 53%;
    right: 25%;
    width: 50%;
    height: 35%;
    border-radius: 100%;
  }

  .circle_3 {
    visibility: hidden;
  }

  .text_box {
    position: static;
    width: 80%;
  }
}

@media (max-width: 375px) {
  .contact_us {
    font-family: Inter;
    bottom: 35%;
  }

  .contact_us p {
    font-size: 1.5rem;
  }

  .contact_us h2 {
    font-size: 2rem;
  }
}
