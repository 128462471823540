.main_container {
  width: 80%;
  max-width: var(--page-width);
  margin: 0 auto 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  overflow: visible;
}

.scrollable {
  overflow-y: auto;
  height: 125px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: -1rem;
}
