.campaign_image {
  grid-area: 1 / 1 / 2 / 2; 
}

.text_overlay {
  grid-area: 1 / 1 / 2 / 2; 
}

.progress_bar::-webkit-progress-bar {
  border-radius: 18px;
  background-color: var(--light-grey);
}

.progress_bar::-moz-progress-bar {
  border-radius: 18px;
  background-color: var(--light-grey);
}

.progress_bar::-webkit-progress-value {
  border-radius: 18px;
  background-color: var(--default-orange);
}

.btn_selector {
  outline: none;
  transition: background-color 0.3s ease;
}

/* Donations */
.table_styles {
  border-spacing: 0 15px;
}

.table_styles tbody tr {
  box-shadow: 0px 4px 4px 0px var(--grey-shadow);
  border-radius: 0.75rem;
}

/* Add a hover effect for table rows */
.table_styles tbody tr:hover {
  background-color: var(--light-grey);
}

.table_styles tbody tr:hover input {
  background-color: var(--light-grey);
}

.table_styles tbody tr:hover td {
  background-color: var(--light-grey);
}

@media (max-width: 1024px) {
  .dashboard_content {
    width: 76%;
  }

  .video {
    margin-left: 0;
    padding-left: 0;
  }
}

@media (max-width: 912px) {
  .dashboard_content {
    width: 75%;
  }
}

@media (max-width: 853px) {
  .dashboard_content {
    width: 100%;
  }

  .header {
    margin-top: 2rem;
  }
}

@media (max-width: 540px) {

  .donation_page_button {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}

@media (max-width: 430px) {
  .dashboard_content {
    width: 100%;
  }
}