.top_section {
  max-height: 500px;
  height: 25vw;
}

@media (min-width: 1537px) {
  .top_section {
    height: 21vw;
    margin-bottom: 50px;
  }
}

@media (max-width: 1536px) {
  .top_section {
    height: 21vw;
    margin-bottom: 50px;
  }
}

@media (max-width: 1200px) {
  .top_section {
    margin-bottom: 30px;
  }
}

@media (max-width: 600px) {
  .top_section {
    margin-bottom: 100px;
  }
}

.dog_img {
  bottom: 460px;
}

@media (min-width: 1537px) {
  .dog_img {
    bottom: 167%;
  }
}

@media (max-width: 1536px) {
  .dog_img {
    bottom: 167%;
  }
}

@media (max-width: 1200px) {
  .dog_img {
    bottom: 168%;
  }
}

@media (max-width: 900px) {
  .dog_img {
    bottom: 168%;
  }
}

@media (max-width: 600px) {
  .dog_img {
    bottom: 168%;
  }
}

.gradient_box {
  bottom: 22px;
  background: linear-gradient(
    118.98deg,
    var(--default-orange) -2.11%,
    var(--default-blue) 63.58%
  );
}

@media (min-width: 1537px) {
  .gradient_box {
    bottom: -1%;
  }
}

@media (max-width: 1536px) {
  .gradient_box {
    bottom: -1%;
  }
}

.search_box {
  top: 18vw;
  transform: translateX(-50%);
  max-width: 1086px;
  width: 80vw;
  border-radius: 20px;
  box-shadow: 0px 10px 50px 0px var(--blue-shadow);
  background: var(--default-orange);
  padding: 15px;
}

@media (min-width: 1537px) {
  .search_box {
    top: 80%;
    height: 45%;
  }
}

@media (max-width: 1536px) {
  .search_box {
    top: 80%;
    height: 45%;
  }
}

@media (max-width: 1200px) {
  .search_box {
    top: 50%;
    height: 100%;
  }
}

@media (max-width: 900px) {
  .search_box {
    top: 50%;
    height: 110%;
  }
}

@media (max-width: 600px) {
  .search_box {
    top: 30%;
    height: 260%;
  }
}

@media (max-width: 540px) {
  .search_box {
    top: 30%;
    height: 330%;
  }
}

@media (max-width: 430px) {
  .search_box {
    height: 450%;
  }
}

@media (max-width: 344px) {
  .search_box {
    height: 500%;
  }
}

@media (max-width: 1200px) {
  .search_inputs {
    column-gap: 100px;
  }
}

@media (max-width: 912px) {
  .search_inputs {
    row-gap: 15px;
  }
}

@media (max-width: 540px) {
  .search_inputs {
    padding-left: 85px;
    justify-content: flex-start;
  }
}

@media (max-width: 430px) {
  .search_inputs {
    padding-left: 40px;
    justify-content: flex-start;
  }
}

@media (max-width: 390px) {
  .search_inputs {
    padding-left: 25px;
  }
}

@media (max-width: 344px) {
  .search_inputs {
    padding-left: 0px;
  }
}

.inputfields {
  color: var(--default-white);
  font-family: Finger Paint;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (max-width: 900px) {
  .no_events {
    margin-top: 18rem;
    margin-bottom: 6rem;
    text-align: center;
    /* min-height: 100vh; */
  }
}

.upcoming_events_box {
  max-width: 1086px;
  margin: 8% auto 5% auto;
}

@media (max-width: 1200px) {
  .upcoming_events_box {
    margin-top: 15%;
    margin-bottom: 12%;
  }
}

@media (max-width: 900px) {
  .upcoming_events_box {
    margin-bottom: 15%;
  }
}

@media (max-width: 600px) {
  .upcoming_events_box {
    margin-top: 20%;
  }
}

@media (max-width: 540px) {
  .upcoming_events_box {
    margin-top: 40%;
  }
}

@media (max-width: 430px) {
  .upcoming_events_box {
    margin-top: 65%;
  }
}

.upcoming_events_search {
  max-width: 1086px;
  margin: 8% auto 3% auto;
}

@media (max-width: 1200px) {
  .upcoming_events_search {
    margin-top: 15%;
  }
}

@media (max-width: 600px) {
  .upcoming_events_search {
    margin-top: 20%;
  }
}

@media (max-width: 600px) {
  .upcoming_events_search {
    margin-top: 20%;
  }
}

@media (max-width: 540px) {
  .upcoming_events_search {
    margin-top: 40%;
  }
}

@media (max-width: 430px) {
  .upcoming_events_search {
    margin-top: 65%;
  }
}

.upcoming_events_single {
  max-width: 1086px;
  margin: 8% auto 5% auto;
}

@media (max-width: 1536px) {
  .upcoming_events_single {
    margin-bottom: 7%;
  }
}

@media (max-width: 1200px) {
  .upcoming_events_single {
    margin-bottom: 12%;
  }
}

@media (max-width: 900px) {
  .upcoming_events_single {
    margin-top: 15%;
    margin-bottom: 20%;
  }
}

@media (max-width: 600px) {
  .upcoming_events_single {
    margin-top: 20%;
  }
}

.previous_events_box {
  max-width: 1086px;
  margin: 0 auto 5% auto;
}

.previous_events_search {
  max-width: 1086px;
  margin: 0 auto 5% auto;
}

@media (max-width: 1200px) {
  .previous_events_search {
    margin-bottom: 12%;
  }
}

@media (max-width: 900px) {
  .previous_events_search {
    margin-bottom: 15%;
  }
}

.previous_events_single {
  max-width: 1086px;
  margin: 10% auto 5% auto;
}

@media (min-width: 1537px) {
  .previous_events_single {
    margin-top: 5%;
  }
}

@media (max-width: 1536px) {
  .previous_events_single {
    margin-bottom: 7%;
  }
}

@media (max-width: 1200px) {
  .previous_events_single {
    margin-bottom: 12%;
  }
}

@media (max-width: 900px) {
  .previous_events_single {
    margin-top: 15%;
    margin-bottom: 20%;
  }
}

@media (max-width: 600px) {
  .previous_events_single {
    margin-top: 28%;
    margin-bottom: 15%;
  }
}

.filter_section {
  max-width: 1086px;
  width: 80vw;
  margin-bottom: 50px;
}

@media (max-width: 600px) {
  .filter_section {
    flex-direction: column;
    justify-content: center;
  }
}

.filter_section h2 {
  width: 366px;
  height: 59px;
  font-family: Finger Paint;
  color: var(--default-orange);
}

@media (max-width: 600px) {
  .filter_section h2 {
    margin-top: 10%;
    margin-bottom: 15%;
  }
}

@media (max-width: 344px) {
  .filter_section h2 {
    font-size: 2rem;
  }
}

.pet_card {
  box-shadow: 0 0 4px darkgrey;
  height: 334.41px;
  max-width: 338px;
  width: 80vw;
  border-radius: 18.95px;
}

.pet_card:hover {
  box-shadow: 0 0 8px darkgrey;
}

.card_img {
  border-radius: 0 0 18.95px 18.95px;
}

.card_content {
  flex: 1;
}

.date {
  width: 28.43px;
  height: 48.33px;
}

.date .month {
  color: var(--default-orange);
}

.date .day {
  font-family: Finger Paint;
}

.info {
  flex: 2;
  /* margin: 0; */
}

.info h3 {
  font-family: Finger Paint;
}

@media (max-width: 768px) {
  .create_banner {
    margin-top: 3rem;
    display: flex;
    min-height: 29rem;
    padding: 0;
  }
}

.create_banner .img_text {
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.5%;
  display: flex;
  justify-content: space-around;
}

.img_text {
  width: min-content;
}

@media (min-width: 1537px) {
  .img_text {
    flex-direction: row;
    align-items: center;
    gap: 2.5rem;
    top: 7%;
  }
}

@media (max-width: 1536px) {
  .img_text {
    flex-direction: row;
    align-items: center;
    gap: 2.5rem;
    top: -16%;
  }
}

@media (max-width: 1200px) {
  .img_text {
    gap: 1.5rem;
    top: -50%;
  }
}

@media (max-width: 900px) {
  .img_text {
    top: -100%;
  }
}

@media (max-width: 768px) {
  .create_banner .img_text {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .img_text {
    gap: 0.5rem;
    top: -50%;
  }
}

@media (max-width: 820px) {
  .img_text img {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .img_text img {
    max-width: 310px;
    min-width: 300px;
  }
}

@media (max-width: 600px) {
  .img_text img {
    width: 50%;
  }
}

.text_box {
  width: 60%;
  max-width: 652px;
  margin: 80px 0 25px 0;
  flex-grow: 2;
}

@media (min-width: 1537px) {
  .text_box {
    margin: 0;
  }
}

@media (max-width: 1536px) {
  .text_box {
    margin: 0;
    gap: 1.4rem;
  }
}

@media (max-width: 1200px) {
  .text_box {
    gap: 0.5rem;
  }
}

@media (max-width: 900px) {
  .text_box {
    gap: 0.1rem;
  }
}

.text_box h2 {
  font-family: Finger Paint;
  color: var(--default-orange);
}

@media (max-width: 1536px) {
  .text_box h2 {
    font-size: 2rem;
    line-height: 1.3;
  }
}

@media (max-width: 900px) {
  .text_box h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .text_box h2 {
    margin-top: 1rem;
  }
}

@media (max-width: 600px) {
  .text_box h2 {
    font-size: 1.5rem;
  }
}

.text_box p {
  width: 65%;
}

@media (max-width: 1536px) {
  .text_box p {
    font-size: 1.5rem;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .text_box p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .text_box p {
    margin-top: 1rem;
  }
}


@media (max-width: 768px) {
  .event_title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
