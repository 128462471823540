
 .nonprofit_profile_form_label_tall {
  height: 83%;
  display: flex;
  flex-direction: column;
}

.nonprofit_profile_form_label_tall > textarea {
  flex-grow: 1; 
  resize: vertical;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.nonprofit_profile_form_button_array {
  display: flex;
  justify-content: center;
}

.nonprofit_profile_form_button_array > button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  height: 30px;
  margin-left: 1rem;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font: inherit;
  font-size: 1rem;
  text-decoration: none;
  background: var(--default-orange);
}

.nonprofit_profile_form_button_array > button:disabled {
  background-color: lightgrey;
}

.img_button:hover {
  background: var(--hover-orange); /* Define your hover color here */
}