.foster_interest_form_label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
}

.foster_interest_form_label > input {
  height: 2rem;
  background-color: white;
  border: solid;
  border-width: 1px;
  border-color: lightgrey;
  color: black;
}

.foster_interest_form_label {
  flex: 1;
}

.button_row > button:disabled {
  background-color: lightgrey;
}

@media (max-width: 1024px) {
  .dashboard_content {
    padding-right: 2rem;
    margin-left: 2rem;
  }
}

@media (max-width: 853px) {
  .dashboard_content {
    width: 100%;
    padding: 1rem;
    margin: 0;
  }
}

@media (max-width: 540px) {
  .form_row {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
