.footer_container {
  background-color: #46a4dd;
  color: white;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  gap: 2rem;
  width: 100%;
  box-sizing: border-box;
}

.footer_newsletter {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.footer_newsletter h2 {
  color: white;
  margin-bottom: 1rem;
}

.footer_newsletter input[type="email"] {
  font-size: 1rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: none;
  outline: none;
  width: inherit;
  border-radius: 4px;
}

.footer_newsletter button {
  padding: 0.5rem 1rem;
  background-color: #46a4dd;
  text-align: right;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  transition: background-color 0.3s ease;
}

.footer_btn {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.footer_links {
  display: flex;
  flex-direction: row;
  gap: 4rem;
}

.footer_column h4 {
  margin-bottom: 0.5em;
}

.footer_column ul {
  list-style: none;
  padding: 0;
}

.footer_column li {
  margin-bottom: 1rem;
}

.footer_column p {
  text-wrap: balance;
  max-width: 24rem;
}

/* Add media queries for responsiveness */
@media (max-width: 1200px) {
  .footer_container {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .footer_links {
    flex-direction: column;
    gap: 1rem;
  }
}
