.download_qr_button {
  padding: 0.5rem;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 2rem;
}

.copy_url_button {
  padding: 0.5rem;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 2rem;
}

.qr_btn_container {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.qr_modal_title {
  padding: 0;
  margin: 1rem 0;
  color: var(--default-orange);
}

.qr_modal_content {
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 2501; /* DO NOT CHANGE */
}

.qr_modal_content > button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  background: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #333;
  width: 5% !important;
}
