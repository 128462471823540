.shop-product {
	max-width: 90rem;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 8rem;
  padding-bottom: 8rem;
  box-shadow: 0 0 5px lightgrey;
  gap: 2rem;
}
.shop-product > div {
  padding: 0 10rem;
  text-align: left;
}

.shop-product-title {
  color: var(--default-orange) !important;
}

.shop-product-header {
  display: flex;
  gap: 2.5rem;
  height: fit-content;
}
.shop-product-header > div {
  flex: 1;
}

.split-container {
  display: flex;
	gap: 2rem;
}

.half-width {
  width: 50%;
}

.product-info {
  width: 50%;
}

.price {
	color: var(--default-blue) !important;
}

.shop-media-carousel {
  overflow: hidden;
  height: 30rem;
}
.shop-media-carousel > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.shop-header-content {
  flex: 1;
}
.shop-header-content > p {
  margin-top: 0;
}
.shop-header-content > h1 {
  color: var(--default-orange);
}

.item-selections {
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60%;
	margin-bottom: 1rem;
}

.item-selections > select {
	width: 100%;
	padding: 0.5rem;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-sizing: border-box;
}

.size-chart-button {
	background-color: none;
  color: black;
  border: none;
  border-radius: 4px;
  margin-bottom: 1rem;
	height: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.selector-text {
  margin-right: 1rem;
  font-weight: bold;
}

.add-cart-button {
	display: inline-block;
  margin-top: 2rem;
  margin-right: 1rem;
  padding: 0.6rem 1rem;
  background-color: #46A4DD;
  color: white;
  text-decoration: none;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
	border: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
	cursor: pointer;
}
.add-cart-button:hover {
  background-color: #0396D5;
}
.view-cart-button {
  display: inline-block;
  margin-top: 2rem;
  padding: 0.6rem 1rem;
  background-color: var(--default-orange);
  color: white;
  text-decoration: none;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
	border: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
	cursor: pointer;
}

@media (max-width: 768px) {
  .shop-product-header,
  .split-container {
      flex-direction: column;
  }

  .shop-product > div,
  .half-width,
  .product-info,
  .item-selections {
      padding: 0 1rem; /* Adjust padding for smaller screens */
      width: 100%; /* Full width for mobile */
  }

  .shop-product-header > div,
  .half-width,
  .product-info {
      flex: none; /* Disable flex sizing */
  }

  .item-selections {
      flex-direction: column; /* Stack selectors vertically */
      align-items: flex-start; /* Align items to the start */
  }

  .item-selections > select {
      width: 100%; /* Full width for selectors */
  }

  .shop-header-content {
      padding-top: 1rem; /* Add some padding on top when stacked */
  }

  .shop-media-carousel,
  .shop-media-carousel > img {
      height: auto; /* Adjust height to be auto on small screens */
  }

  .size-chart-button,
  .add-cart-button,
  .view-cart-button {
      width: 100%; /* Full width for buttons */
      margin: 1rem 0; /* Adjust margin for stacking */
  }
}
