.choose_cancel_button {
  display: flex;
  width: auto;
  height: 1rem;
  justify-content: right;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: none;
}

.choose_cancel_div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.choose_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2000; /* Make sure this is high enough to be above everything else */
}

.modal_choose_donor {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.modal_choose_donor select {
  display: block;
  width: 80%;
  height: 2rem;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid var(--unselected-grey);
}

.modal_choose_donor h2 {
  color: var(--default-orange);
}

.modal_choose_donor h3 {
  margin-top: 1rem;
}
