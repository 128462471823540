.rp-page {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #f0f0f0;
}

.rp-card {
	background: #fff;
	padding: 2rem;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	max-width: 400px;
	width: 100%;
	box-sizing: border-box;
}

.rp-card input {
	margin-bottom: 1rem;
	padding: 0.5rem;
	border: 1px solid #ddd;
	width: inherit;
	border-radius: 4px;
}

.rp-button {
	padding: 0.5rem;
	font-size: 1rem;
	margin-bottom: 1rem;
	display: flex;
	background-color: var(--default-blue);
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	width: 30%;
	justify-content: center;
	align-items: center;
}

.rp-heading {
	color: var(--default-orange);
	text-align: center;
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

.rp-error {
	color: red;
	margin-bottom: 1rem; /* Space above the rp button */
	text-align: center;
}