/* TermsModal.css */
.tos_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2000;
  overflow-y: auto;
}

.modal.open {
  display: flex;
}

.tos_modal_overlay {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tos_modal_content {
  position: relative;
  background-color: white;
  padding: 20px;
  text-align: left;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 40%;
}

.tos_modal_content h3 {
  margin-top: 0;
}

.tos_modal_content label {
  display: block;
  margin-bottom: 10px;
}

.tos_modal_buttons {
  text-align: center;
  margin-top: 10px
}

.tos_modal_buttons button {
  padding: 5px 10px;
  border: none;
  background-color: var(--default-blue);
  color: white;
  cursor: pointer;
  width: 20rem;
  margin-left: 3rem;
  margin-right: 3rem;
}


@media  (max-width: 980px) {
  .tos_modal_buttons button {
    width: 7rem
  }
}