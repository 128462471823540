@import url("https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap");
@import "./tailwind.css";
@import "~quill/dist/quill.snow.css";
@import "~quill-emoji/dist/quill-emoji.css";

:root {
  --default-blue: #46a4dd;
  --default-red: #e75a4f;
  --dark-blue: #0396d5;
  --light-grey: #dddddd;
  --unselected-grey: #aaaaaa;
  --dark-grey: #595868;
  --border-grey: #b2b2b2;
  --default-orange: #fe994f;
  --default-cream: #fffaf5;
  --default-white: #ffffff;
  --page-width: 1200px;
  --mobile-page-width: 100%;

  --orange-light1: #feb580;
  --orange-light2: #fea667;
  --orange-dark1: #fe8834;
  --orange-dark2: #fe791b;

  --blue-light: #bae6fd;
  --blue-dark1: #3d9fdb;
  --blue-dark2: #2894d7;
  --blue-shadow: #3d37f140;

  --grey-hover: #d9d9d9;
  --grey-click: #cccccc;
  --grey-dropdown-bg: #c4c4c436;
  --grey-icon: #1c1c1c;
  --grey-shadow: #00000040;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Usual reset */
body,
html {
  margin: 0;
  padding: 0;
  height: 100vh;
}

#root {
  min-height: 100vh;
}

.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 10000000;
}

.newsletter-input {
  font-size: 1rem;
  padding: 0.5rem;
  background-color: var(--light-grey);
  height: inherit;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 4px;
  width: 15rem;
  justify-content: center;
  align-items: center;
}
