@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p {
    @apply font-roboto;
  }

  /* Remove spin buttons for Webkit browsers (Chrome, Safari) */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Removes spin buttons on Chrome */
    appearance: none; /* Adds cross-browser compatibility */
    margin: 0; /* Ensures no extra spacing */
  }

  /* Remove spin buttons for Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  /* For Edge and other browsers, just hiding the spin buttons with the appearance property */
  input[type="number"] {
    appearance: none;
  }
}
