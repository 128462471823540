.login_card {
  background: var(--default-blue);
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
}

.login_card h2 {
  font-family: "Finger Paint";
}

.login_card > input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid var(--border-grey);
  width: inherit;
  border-radius: 4px;
}

.login_button {
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  display: flex;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 30%;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .login_card {
    /* box-shadow: unset;
    justify-content: unset;
    align-items: unset; */
    width: 70%;
    padding: 2rem;

  }

  .login_card h2{
    margin-top: 1rem;
    margin-bottom: 2rem;

  }

  .login_card > input {
    width: 100%;
  }

  /* .login_card button {
    color: var(--unselected-grey);
  } */
}
