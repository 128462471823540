.fp_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.fp_card {
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
}

.fp_card input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  width: inherit;
  border-radius: 4px;
}
