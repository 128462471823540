.payment_form {
  margin-top: 1rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.submit_btn {
  padding: 0.5rem 1rem;
  background-color: #46a4dd;
  text-align: center;
  font-size: 0.9rem;
  color: white;
  min-width: 20%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: inherit;
  outline: none;
  transition: background-color 0.3s ease;
}
