.homepage {
  max-width: 91.5rem;
  width: 100%;
  box-shadow: 0 0 5px lightgrey;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 0;
  /* padding-bottom: 8rem; */
  overflow-x: hidden;
}
.homepage > div {
  width: 100%;
  margin: 0 auto;
  margin-top: 6rem;
  padding: 0 10rem;
}

.homepage_section_header {
  width: 100%;
  display: flex;
  color: var(--default-orange);
}
.homepage_section_header > h1 {
  margin: 0;
}

/* Slideshow */
.homepage > .slideshow_container {
  padding: 0;
  height: 100%;
}
.homepage > .slideshow_container > .slick_slider {
  height: 40rem;
}
.homepage > .slideshow_container > .slick_slider div {
  height: 110%;
}

.announcements_cover {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Simplified column setup */
  grid-template-rows: repeat(
    7,
    4.5rem
  ); /* Distribute the available space evenly across rows */
  align-items: center;
  justify-items: center;
  gap: 1px; /* Adjust the gap as needed */
  text-align: center;
  max-height: 40rem;
  justify-content: center; /* Center the entire grid horizontally */
}

.announcements_cover_mobile {
  display: grid;
  grid-template-columns: 100px auto 100px auto 100px;
}
.announcements_cover_mobile > p {
  text-align: center;
  margin: 0rem;
}
.announcements_cover_mobile_header {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-size: xx-large;
  font-weight: bold;
  color: var(--default-orange);
}
.announcements_cover_mobile > .announcements_cover_mobile_arrow {
  font-size: 2rem;
  align-self: center;
  justify-self: center;
  color: var(--default-blue);
}
.announcements_cover_image {
  width: 10rem;
  height: 10rem;
  grid-area: 3 / 3 / 6 / 6;
  place-self: center;
  margin: auto auto;
  object-fit: cover;
  border-radius: 100%;
}
.announcements_cover_mobile_image {
  width: 5rem;
  height: 5rem;
  grid-area: 3 / 3 / 6 / 6;
  place-self: center;
  margin: auto auto;
  object-fit: cover;
  border-radius: 100%;
}
.announcements_cover > h2 {
  color: var(--default-orange);
  grid-area: 1 / 2 / 2 / 5;
  justify-self: center;
  padding: 0 1rem;
}
.announcements_cover > p {
  text-align: center;
  margin: 0rem;
  cursor: pointer;
}

.announcements_cover > img {
  border-radius: 50%;
  width: 40%;
  height: 100%;
  grid-row: 3 / 6;
  grid-column: 2 / 5;
  justify-self: center;
  align-self: center;
}

.announcement_slide {
  max-height: 50rem;
  display: flex !important;
  flex-direction: row-reverse;
  overflow: hidden;
}
.announcement_slide-content {
  width: 100%;
}
img.announcement_slide_content {
  /* this is a background image */
  width: 100%;
  object-fit: contain;
  height: 100vh;
}
div.announcement_slide_content {
  width: 100%;
  padding: 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div.announcement_slide_content > h1 {
  margin: 0;
  text-align: center;
}

/* section dark */
.homepage_section_dark {
  width: 100%;
  margin-top: 3rem;
  background-color: var(--default-blue);
  display: flex;
  flex-direction: column;
  align-items: right;
}
.homepage_section_dark > div {
  margin: 2rem 0;
  display: flex;
  color: white;
  gap: 2rem;
}
.homepage_section_dark > div > h1 {
  margin: 0;
  width: 100%;
}
.homepage_section_dark > div > p {
  margin: 0;
}
.homepage_section_dark .homepage_section_dark_buttons {
  margin-left: auto;
  margin-top: auto;
}

/* section */
.homepage_section {
  display: flex;
  flex-direction: column;
  background-color: #fbfbfb;
}
.homepage iframe {
  margin: 4rem auto;
  border-radius: 20px;
  box-sizing: border-box;
}

.homepage_section_image_container {
  width: 64.5%;
  margin: 0 !important;
}

.homepage_section_image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* reasons */
.homepage_reasons_container {
  margin: 2rem 0;
  display: flex;
  gap: 2rem;
  padding: 2rem 0;
}
.homepage_reasons_card {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 1.5rem;
}
.homepage_reasons_card > svg {
  color: white;
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.homepage_reasons_card > p {
  color: white;
  text-align: center;
}

.homepage_reasons_card > h3 {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}

.logo_slide img {
  width: 8rem;
  height: 8rem;
  object-fit: contain;
}

/* help right now */
.homepage > .homepage_two_tone_bg {
  background: linear-gradient(
    to top,
    var(--default-orange) 0%,
    var(--default-orange) 50%,
    white 50%,
    white 100%
  );
  padding: 0;
}
.homepage_two_tone_bg > h1 {
  color: var(--default-orange);
  padding: 0 10rem;
}
.homepage_campaigns_container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

/* buttons */
.homepage_button {
  font-size: 1rem;
  display: inline-flex;
  text-decoration: none;
  background-color: var(--default-orange);
  color: white;
  height: 4rem;
  width: 8rem;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.homepage_button_transparent {
  background-color: transparent;
  color: var(--white);
  border: none;
}
.homepage_button_about {
  font-size: 1.5rem;
  display: inline-flex;
  text-decoration: none;
  background-color: white;
  color: var(--default-blue);
  border-radius: 20px;
  height: 3rem;
  width: 12rem;
  align-items: center;
  justify-content: center;
}

.announcement_button {
  background-color: var(--default-orange);
  color: white;
  font-size: 1rem;
  width: 10rem;
  height: 2rem;
}
.announcement_button_alt {
  background-color: var(--default-blue);
  border: 1px solid var(--default-blue);
}

/* Campaigns */

.homepage_search_card {
  max-width: 18rem;
  width: 100%;
  flex: 0 0 22.5rem;
  flex-direction: column;
  border: 0px var(--unselected-grey) solid;
  border-radius: 8px;
  box-shadow: 0 0 4px darkgrey;
  display: flex;
  overflow: hidden;
  padding: 1.25rem;
  gap: 0.75rem;
  height: 22rem;
  text-align: left;
  user-select: none;
  background-color: white;
  color: black;
  cursor: pointer;
}

.homepage_search_card:hover {
  box-shadow: 0 0 8px darkgrey;
}
.homepage_search_card > div {
  flex: 1;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.homepage_search_card > div > h3 {
  font-family: "Finger Paint", cursive;
  width: 100%;
  margin: 0;
  color: var(--default-orange);
}
.homepage_search_card > div > p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  flex: 1;
}

.homepage_search_card img {
  width: 8rem;
  height: 8rem;
  margin: 0 auto;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
  border: 0.5rem;
}

.homepage_search_card_footer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: black;
  font-size: xx-large;
}
.homepage_search_card_footer > p {
  margin: 0;
  color: var(--default-orange);
  font-size: 1.25rem;
}
.homepage_search_card_footer > svg {
  margin-left: auto;
  width: 1.4rem;
}

.homepage_progress_bar_background {
  width: 100%;
  height: 0.5rem;
  background-color: var(--light-grey);
  border-radius: 4rem;
}

.homepage_progress_bar {
  height: 100%;
  background-color: var(--default-orange);
  border-radius: inherit;
}

.category_content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.custom_slide_subheading {
  font-size: 1.2rem;
}


.custom_slide_get_started {
  padding: 0.5rem 1rem;
}

@media (max-width:1024px) {
  .custom_slide_heading {
    margin-top: 9.5rem;
  }
}

@media (max-width: 980px) {
  .homepage_section iframe {
    width: 350px;
  }
  .homepage_section_image_container {
    width: 100%;
    padding: 0;
  }
  .homepage_section_dark_header {
    display: none !important;
  }

  .custom_slide_overlay {
    padding-top: 9.5rem;
  }

  .custom_slide_buttons {
    margin-left: 3rem;
  }

  .custom_slide_columns {
    margin-left: 3rem;
    gap: 0rem;
  }
}

/* Media queries for responsive */
@media (max-width: 1024px) {
  .custom_slide_heading {
    margin-top: 9.5rem;
  }
}

@media (max-width: 912px) {
  .custom_slide_heading {
    margin-top: 2rem;
  }
}

@media (max-width: 853px) {
  .custom_slide_heading {
    margin-top: 0rem;
  }
}

@media (max-width: 768px) {
  .announcements_cover p {
    font-size: 0.8rem; /* Smaller font size */
    margin: 0.5rem; /* Reduced margin */
  }
  .announcements_cover {
    gap: 0.1rem;
  }

  .announcements_cover img {
    grid-area: 3 / 3 / 6 / 6; /* Ensure the image does not overlap text */
  }
  .announcements_cover > img {
    border-radius: 50%;
    width: 80%;
    height: 60%;
    grid-row: 3 / 6;
    grid-column: 2 / 5;
    justify-self: center;
    align-self: center;
  }

  .homepage,
  .homepage > .homepage_section {
    padding: 0 1rem;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
  }

  .homepage_section iframe {
    width: 100%;
  }

  .homepage_section_header {
    margin-bottom: 1rem;
    width: 100% !important;
    padding-right: 0 !important;
    align-items: center;
    justify-content: center;
  }

  .homepage > div {
    padding: 0;
  }

  .announcement_slide {
    flex-direction: column;
  }
  img.announcement_slide_content {
    width: 100%;
    object-fit: cover;
  }
  div.announcement_slide_content {
    width: 100%;
    height: 20000px;
  }

  .homepage_two_tone_bg > h1 {
    padding: 0;
    text-align: center;
  }
  .homepage_campaigns_container {
    display: flex;
    gap: 1rem;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0 auto;
  }

  .homepage_search_card_container_mobile {
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
  }
  .homepage_search_card_mobile {
    padding: 1rem;
    max-width: 100%;
    flex: 1 1 fit-content;
    margin: 0;
  }
  .homepage_search_card_mobile p {
    margin: 0;
  }
  .homepage_search_card_mobile img {
    border: none;
    border-radius: 5px;
  }
  .homepage_search_card_mobile > .homepage_search_card_flex_horizontal {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .homepage_search_card_flex_horizontal > .homepage_search_card_flex_vertical {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    text-align: right;
  }
  .homepage_search_card_flex_horizontal
    > .homepage_search_card_flex_vertical
    > p {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
  }
  .homepage_search_card_footer {
    box-sizing: border-box;
    background-color: var(--default-blue);
    max-height: 2.5rem;
    padding: 1rem 0.5rem;
    border-radius: 5px;
  }
  .homepage_search_card_footer,
  .homepage_search_card_footer p {
    color: white;
  }

  .homepage_reasons_container {
    display: flex;
    flex-direction: column;
  }

  .custom_slide_overlay {
    width: 100%;
    height: 100%;
    top: 0;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem;
  }

  .custom_slide_heading {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2.6rem;
    margin-top: 2.5rem;
  }

  .custom_slide_buttons {
    margin-left: 0;
    align-items: center;
    width: 100%;
  }

  .custom_slide_button {
    width: 80%;
  }

  .custom_slide_columns {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin: 0;
  }

  .custom_slide_left_column,
  .custom_slide_right_column {
    align-items: center;
    width: 100%;
  }

  .custom_slide_subheading {
    text-align: center;
    gap: 0;
    margin: 0;
    margin-bottom: 1rem;
  }

  .custom_slide_join_button {
    width: 80%;
    margin: 0;
  }

  .custom_slide_label {
    text-align: center;
  }

  .custom_slide_input_container {
    flex-direction: column;
    width: 80%;
    align-items: center;
  }

  .homepage_button {
    padding: 0;
  }

  .custom_slide_input {
    width: 100%;
  }

  .custom_slide_get_started {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .homepage_section_header {
    flex-direction: column;
  }

  .homepage-reasons-container {
    flex-direction: column;
  }
}

@media (max-width: 375px) {
  .custom_slide_heading {
    margin-top: 0;
  }
}