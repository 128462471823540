.blog_page {
  justify-content: center;
  align-items: center;
  max-width: var(--page-width);
  padding: 0 2rem;
  overflow-x: hidden;;
}

.blog_page h1 {
  margin-bottom: 0;
}

.paragraph {
  text-align: left;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: 2rem;
}

.paragraph li {
  list-style: bullet;
  margin-left: 2rem;
}

.carousel_slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.success_video_carousel .slick_dots {
  height: 2rem;
  bottom: 0rem;
}
.success_video_carousel > .slick_slider div {
  height: 100%;
  display: flex;
}

.success_video_carousel {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  object-fit: contain;
  max-width: 60rem;
  width: 80rem;
  height: auto;
}

.success_video_carousel > .slick_slider {
  width: 40rem;
  height: 20rem;
  padding-bottom: 2rem;
}

.success_video_carousel .video {
  width: 150%;
  height: inherit;
  display: flex;
  align-items: left;
  justify-content: left;
}

.success_video_carousel .slick_next {
  z-index: 1;
  right: 0;
  border-radius: 100%;
  background-color: var(--default-orange);
  padding-top: 1px;
}
.success_video_carousel .slick_prev {
  z-index: 1;
  left: 0;
  border-radius: 100%;
  background-color: var(--default-orange);
  padding-top: 1px;
}

.blog_img {
  width: 100%;
  max-width: 40rem;
  object-fit: cover;
  display: block;
  margin: 1rem auto;
}

.blog_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header_img {
  width: 100%;
  max-width: 40rem;
  object-fit: cover;
  display: block;
  margin: 1rem auto;
}

.header_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
