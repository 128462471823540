.unsubscribe_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.unsubscribe_card {
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
}

.unsubscribe_card .form_row {
  display: flex;
  justify-content: space-between;
}

.unsubscribe_card .form_row input,
.unsubscribe_card .form_row textarea {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Styles for inputs and textarea not in .form_row should be full width */
.unsubscribe_card input,
.unsubscribe_card textarea:not(.form_row textarea) {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.unsubscribe_card button {
  padding: 0.5rem 1rem;
  background-color: var(--default-blue);
  text-align: center;
  font-size: 0.9rem;
  color: white;
  width: 6rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: inherit;
  outline: none;
  transition: background-color 0.3s ease;
  margin: 0 auto;
}

.unsubscribe_card button:hover {
  background-color: #0056b3;
}

.form_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
