.modal_email_template {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.choose-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2000; /* Make sure this is high enough to be above everything else */
}

.template_box {
  border: 1px solid var(--unselected-grey);
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 5px;
}

.modal_email_template ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
}

.modal_email_template h2 {
  color: var(--default-orange);
  clear: both;
}

.modal_email_template li:hover {
  background-color: #f2f2f2;
}

.modal_email_template li.selected {
  border: 1px solid #007bff; /* Highlight selected template */
  background-color: #e7f1ff;
}

/* Style for close button */
.modal_email_template button {
  float: right;
  border: none;
  background-color: #ff6b6b;
  color: white;
  padding: 8px 16px;
  /* margin: -20px -20px 20px 0; */
  cursor: pointer;
}

.modal_email_template button:hover {
  background-color: #ff5a5a;
}

.submit_email {
  color: white;
  background-color: var(--default-blue) !important;
  float: none !important;
  margin-top: 1rem !important;
}

.cancel_button {
  padding: 0.5rem;
  background-color: var(--default-red);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.confirmation_prompt_buttons {
  display: flex;
  justify-content: center;
  gap: 4rem;
}
.confirmation_prompt_buttons > button {
  width: 3rem;
}

/* Body content style */
.modal_email_template div {
  padding: 0.4rem;
  /* background: #f9f9f9; */
  border-radius: 4px;
}

.label_container {
  text-align: left;
}

.label_container label {
  display: block;
  margin-bottom: 5px;
}

.label_container input {
  display: block;
  width: 100%;
  height: 2rem;
  margin-right: 5rem;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid var(--unselected-grey);
}

.action_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1rem;
}

.confirm_send {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.confirm_send > button {
  width: 20%;
}

.confirmation_modal > h2 {
  color: var(--default-orange);
}

.preview_heading {
  display: flex;
  justify-content: center;
  color: var(--default-orange);
}

.email_modal_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1700;
}

.confirmation_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2500; /* Higher than the Choose Donor List modal */
}

.confirmation_modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1350;
}
