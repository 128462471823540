@media (max-width: 1024px) {
  .donor_dashboard_block {
    padding-right: 2rem;
    margin-left: 2rem;
  }
}

@media (max-width: 912px) {
  .button_download_csv {
    width: 50%;
  }
}

@media (max-width: 853px) {
  .donor_dashboard_block {
    margin: 0;
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .button_download_csv {
    width: 50%;
  }
}

@media (max-width: 540px) {
  .donor_dashboard_block {
    margin: 0;
    padding: 1rem;
  }

  .buttons {
    padding: 2rem;
  }

  .button_download_csv {
    padding: 2rem;
    width: 50%;
  }

  .pages {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 430px) {
  .pages {
    justify-content: center;
  }
}

@media (max-width: 390px) {
  .buttons {
    padding: 2.5rem;
  }

  .pages {
    align-items: center;
  }
}

@media (max-width: 344px) {
  .button_download_csv {
    width: 80%;
  }
}