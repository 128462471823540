.nonprofit_create_page {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tos_button {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.input_example {
  font-size: 14px !important;
  color: var(--dark-grey);
  margin: 0;
  margin-left: 4px;
  margin-bottom: 2px;
}

.form_tab_col {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
}

.nonprofit_create_card {
  background: #fff;
  margin-bottom: 1rem;
  margin-top: 0.8rem;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 35rem;
  width: 100%; /* Adjust if needed to fit two columns */
  box-sizing: border-box;
}

.nonprofit_create_card .form_row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.nonprofit_create_card .form_row input select,
.nonprofit_create_card .form_row select,
.nonprofit_create_card .form_row textarea {
  /* width: 42%; */
  width: 100%;
  flex: 1;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Styles for inputs and textarea not in .form_row should be full width */
.nonprofit_create_card input,
.nonprofit_create_card textarea:not(.form_row textarea) {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.nonprofit_create_card button {
  padding: 0.5rem;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nonprofit_create_card button:hover {
  background-color: #0396d5;
}
.nonprofit_create_card button:disabled {
  background-color: var(--unselected-grey);
}

.create_heading {
  color: var(--default-orange);
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.create_error {
  color: red;
  /* margin-bottom: 1rem; */
  text-align: center;
  font-size: 12px;
}

.parent_div {
  display: flex;
  position: relative;
  flex-grow: 1;
}

.asterisk_div {
  position: absolute;
  left: 2.5px;
  color: red;
}
.asterisk_div_image {
  position: absolute;
  left: 1px;
  color: red;
}

.ql_container {
  @apply mb-6 text-black;
}
