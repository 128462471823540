.donor_label {
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.button_container {
  flex-grow: 1;
}

@media screen and (max-width: 999px) {
  .dashboard_block {
    display: none;
  }

  .mobile_message {
    display: block; /* Ensure this is visible only on small screens */
    text-align: center;
    padding: 20px;
    font-size: 16px;
  }
}

/* Ensure the mobile message is hidden on larger screens */
.mobile_message {
  display: none;
}
