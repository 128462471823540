.campaign_media_carousel {
  object-fit: scale-down !important;
}

.campaign_media_carousel > :global(.slick-slider) {
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
}
.campaign_media_carousel :global(.slick-dots) {
  margin-top: 10rem;
  display: relative !important;
  bottom: -2;
  height: 2rem;
}
.campaign_media_carousel > :global(.slick-slider div) {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.campaign_media_carousel > :global(.slick-slider img) {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.campaign_media_carousel :global(.slick-next) {
  z-index: 1;
  right: 0;
  border-radius: 100%;
  background-color: var(--default-orange);
  padding-top: 1px;
}
.campaign_media_carousel :global(.slick-prev) {
  z-index: 1;
  left: 0;
  border-radius: 100%;
  background-color: var(--default-orange);
  padding-top: 1px;
}

.campaign_media_carousel > img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}
