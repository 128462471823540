.main {
	display: flex;
	  justify-content: space-between;
	  width: 100%;
	flex-direction: row;
	min-height: 100vh;
  }
  
  .toolbar {
	min-height: 64px;
  }
  
  .sidebar {
	flex-shrink: 0;
	display: flex;
	justify-content: space-between;
  }
  
  .admin_dashboard {
	  display: flex;
	  justify-content: left;
	  flex-grow: 1;
	  width: 100vw;
	  margin: none !important;
	  padding: none !important;
	  height: 300vh;
	  margin-top: 102px;
  }
  
  .collapsed {
	  max-width: calc(100% - 64px);
  }
  
  .expanded {
	  max-width: calc(100% - 240px);
  }