.form_column {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 0.5rem);
}

.form_row {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}

.form_column textarea {
  padding: 0.5rem;
  border: 1px solid #ddd;
  min-height: 4rem;
  /* color: var(--unselected-grey); */
  color: black;
  border-radius: 4px;
  flex: 1;
  margin-bottom: 0.4rem;
  resize: none;
  overflow: auto;
}

.form_column input,
.form_column select,
.form_row input,
.form_row select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  /* color: var(--unselected-grey); */
  color: black;
  border-radius: 4px;
  margin-bottom: 0.4rem;
  flex: 1;
  text-align: left;
  width: 100%;
}

.form_tab_col {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
}

.input_example {
  font-size: 14px !important;
  color: var(--dark-grey);
  margin: 0;
  margin-left: 4px;
  margin-bottom: 4px;
  text-align: left;
}

.sortable_helper {
  z-index: 10000 !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.image_form_row {
  display: flex;
  align-items: center;
  justify-content: left;
}

.image_form_row > a {
  margin-left: inherit;
}

/* .file_input {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
} */

/* .close_button {
  position: absolute;
  margin-right: 1rem;
  max-width: 1rem;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
} */

/* .upload_images_label {
  display: block;
  margin-bottom: 0.5rem;
} */

/* .form_title {
  color: var(--default-orange);
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.form_label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
} */
