.table th {
  width: 25%;
}

.search_panel {
  max-width: 45rem;
  width: 100%;
  display: flex;
  border: 1px var(--unselected-grey) solid;
  border-radius: 2.25rem;
  overflow: hidden;
  height: 2.25rem;
  align-items: center;
  min-width: 22rem;
  margin: 0;
  flex: 1;
}

.search_panel > svg {
  cursor: default;
  color: var(--unselected-grey);
  margin: 0 0.5rem;
}
.search_panel > input {
  flex: 1;
  border: none;
  outline: none;
}

.table {
  border-spacing: 0px 10px;
  border-collapse: separate;
}

.table thead th {
  padding: 10px;
  text-align: center;
  color: var(--unselected-grey);
  width: 13rem;
}

.table tbody td {
  padding: 10px;
  text-align: center;
  background-color: #eef8ff;
  border-style: solid none;
  color: black;
  margin: 5px 0;
}

.table tbody td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table tbody td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.table {
  border-spacing: 0 15px;
}

.table tbody tr {
  box-shadow: 0px 4px 4px 0px var(--grey-shadow);
  border-radius: 0.75rem;
}

/* Add a hover effect for table rows */
.table tbody tr:hover {
  background-color: var(--light-grey);
}

.table tbody tr:hover input {
  background-color: var(--light-grey);
}

.table tbody tr:hover td {
  background-color: var(--light-grey);
}