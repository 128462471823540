.table {
  width: 100%;
  border-spacing: 0 15px;
  border-collapse: separate;
}

.table thead th {
  padding-bottom: 8px;
  font-weight: 500;
}

.table tbody tr {
  box-shadow: 0px 4px 4px 0px var(--grey-shadow);
  border-radius: 0.75rem;
}

.table tbody td {
  padding: 10px;
  font-weight: 500;
}

/* Add a hover effect for table rows */
.table tbody tr:hover {
  background-color: var(--light-grey);
}

.table tbody tr:hover input {
  background-color: var(--light-grey);
}
