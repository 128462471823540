/* Editor.module.css */
.quill_editor_container {
  color: black !important;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.quill_editor_container h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.quill_editor_container h2 {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.quill_editor_container h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.quill_editor_container p {
  font-size: 16px;
  margin-bottom: 0.5rem;
  color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
