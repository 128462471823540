.signup_card {
  background: var(--default-blue);
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.signup_card label{
  color: white;
  display: block;
  text-align: left;
  margin-left: 16px;
}

.header {
  font-family: "Finger Paint";
}

.signup_card input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  width: 90%;
  border: 1px solid var(--border-grey);
  border-radius: 20px;
}

.signup_card select {
  margin-bottom: 1rem;
  padding: 0.5rem;
  color: grey;
  width: 95%;
  height: 50px;
  border: 1px solid var(--border-grey);
  border-radius: 20px;
}

@media (max-width: 540px) {
  .signup_card {
    box-shadow: unset;
    justify-content: unset;
    align-items: unset;
    max-width: 100%;
    box-sizing: border-box;
  }

  .signup_card input {
    padding: 17px 12px;
    border-radius: 16px;
    background: var(--default-white);
    border: 1px solid var(--border-grey);
  }

  .signup_card select {
    border: 1px solid var(--border-grey);
    padding: 17px 12px;
    border-radius: 16px;
    background: var(--default-white);
  }

  .signup_card button {
    color: var(--unselected-grey);
  }
}