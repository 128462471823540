.dashboard {
  max-width: var(--page-width);
  margin-top: 102px;
  min-height: 100vh;
}

.dashboard_nav {
  transition: width 0.3s;
  height: auto;
}

.dashboard_content {
  width: 892px;
}


.dashboard_block_row {
  flex-direction: row;
}

.dashboard_row {
  display: flex;
  gap: 20px;
}

.dashboard_row .dashboard-block {
  flex: 1;
}

#info-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  color: var(--unselected-grey);
}

#info_row div {
  flex: 1 1 0;
}

.dashboard_stats {
  display: flex;
}

.dashboard_stat {
  flex: 1;
}

.dashboard_stat > p {
  color: var(--unselected-grey);
}

.selected_nav_item {
  color: var(--default-blue);
}

.np_btn_item {
  color: var(--unselected-grey);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.np_btn_item:hover,
.dashboard_nav_item:hover {
  background-color: #f1f1f1;
}

.mobile_message {
  display: none;
}

.icon {
  font-size: 24px !important;
  margin-left: 1rem;
  cursor: pointer;
}

@media (max-width: 512px) {
  #info_row {
    flex-direction: column;
  }
  .dashboard_stats {
    flex-direction: column;
  }
}

@media screen and (max-width: 999px) {
  .mobile-message {
    display: block; /* Ensure this is visible only on small screens */
    text-align: center;
    padding: 20px;
    font-size: 16px;
  }
}

@media (max-width: 1176px) {
  .dashboard {
    flex-direction: row;
    width: 100%;
  }

  .dashboard_content {
    width: 100%;
  }
}

@media (max-width: 853px) {
  .dashboard_nav {
    display: none;
  }
  .dashboard {
    flex-direction: column;
  }
  .dashboard_content {
    margin: 0;
    padding: 1rem;
  }
}

@media (max-width: 540px) {
  .dashboard_nav {
    display: none;
  }
  .dashboard {
    flex-direction: column;
  }
  .dashboard_content {
    margin: 0;
    padding: 1rem;
  }
}
