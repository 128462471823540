.policy_container {
  width: 80%;
  margin: 0 auto;
  margin-top: 6rem;
  padding: 2rem;
}

.policy_container h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--default-orange);
}

.policy_content {
  text-align: left;
  line-height: 1.6;
  color: #333;
}

.policy_content h2 {
  margin-top: 2rem;
}

.policy_content p {
  margin-bottom: 1rem;
}
