.createButton {
  @apply bg-cyan-300 hover:bg-cyan-400 text-black text-lg;
}

.searchInput {
  @apply mt-4 w-1/2;
}

.gridContainer {
  @apply max-h-[40rem] overflow-y-auto w-full;
}

.gridItem {
  @apply pb-10;
}
