.header_nav_dropdown {
  position: absolute;
  left: 0; /* Align the dropdown to the right side of the user icon wrapper */
  top: 68px; /* Position the dropdown just below the user icon */
  background-color: #f9f9f9;
  border: 1px solid #dcdcdc;
  min-width: 180px;
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}
.header_nav_dropdown_mobile {
  right: 0;
  top: 100px;
}

@media (max-width: 768px) {
  .header_nav_dropdown {
    background: linear-gradient(179.83deg, #ee9e63 0.14%, #e7690b 159.7%);
    height: calc(100vh - 68px);
  }
}
