.modal {
  position: relative;
  padding: 10px;
  background: white;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: auto;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  overflow: auto;
  padding: 50px;
}

.main_div {
  flex: 1; /* Allows .main-div to expand and take up any available space */
  width: 100%;
  padding-top: 0;
  margin: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.contact_form_container {
  text-align: center;
  max-width: 600px;
  padding: 1rem;
}

.contact_form_container > h1 {
  color: var(--default-blue);
}

.contact_us_form > textarea {
  font-family: Arial, sans-serif;
  font-size: 1rem;
  height: 5rem;
  resize: vertical;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.contact_us_form {
  max-width: 100%;
  width: 720px;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.contact_us_form > button {
  padding: 0;
  margin: 0 auto;
  background-color: var(--default-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: white;
  width: 6rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 2rem;
  text-decoration: none;
  transition: background-color 0.3s;
}

.contact_us_form > input {
  height: 16px;
  font-size: 16px;
  padding: 16px;
  padding-left: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.contact_us_form > input::-webkit-outer-spin-button,
.contact_us_form > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contact_form_container > h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.contact_form_container > form {
  display: flex;
  flex-direction: column;
}

.input_group {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.input_group > input {
  flex: 1;
  height: 16px;
  font-size: 16px;
  padding: 16px;
  padding-left: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.close_button {
  position: absolute;
  margin-right: 1rem;
  max-width: 1rem;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .input_group {
    flex-direction: column;
  }

  .input_group > input {
    flex: none;
  }
  .contact_us_form {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

@media (max-width: 999px) {
  .contact_us_form_row {
    flex-direction: column;
  }
}
