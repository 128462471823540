.blog_form {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 40rem;
	overflow-y: auto;
	padding: 1rem;
	width: 100%;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
	border-top: 0;
	height: inherit !important;
}

.custom_quill_wrapper .ql-toolbar.ql-snow + .ql-container.ql-snow {
	height: inherit;
}

.post_form {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.post_form input {
	color: var(--unselected-grey);
	display: flex;
	align-items: left;
}

.top_section {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.cover_image {
	color: var(--unselected-grey);
}

.subtitles {
	text-align: left;
}

.middle_section {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
}

.img_p_section {
	color: var(--unselected-grey);
	width: 100%;
}

.button_container {
	display: flex;
	justify-content: center;
	gap: 10px;
}

.back_button {
  cursor: pointer;
  margin-right: 18rem;
  margin-left: -4rem;
}