.donor_label {
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.donor_button {
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.button_container {
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.search_block > input {
  font-size: 0.9rem;
  height: inherit;
  border: 1px solid var(--unselected-grey);
  outline: none;
}

.table_styles_override {
  border-spacing: 0 15px;
}

.table_styles_override tbody tr {
  box-shadow: 0px 4px 4px 0px var(--grey-shadow);
  border-radius: 0.75rem;
}

/* Add a hover effect for table rows */
.table_styles_override tbody tr:hover {
  background-color: var(--light-grey);
}

.table_styles_override tbody tr:hover input {
  background-color: var(--light-grey);
}

.table_styles_override tbody tr:hover td {
  background-color: var(--light-grey);
}

@media (max-width: 768px) {
  .table_styles_override tbody tr:hover {
    background-color: rgb(240, 249, 255);
  }

  .table_styles_override tbody tr:hover input {
    background-color: rgb(240, 249, 255);
  }

  .table_styles_override tbody tr:hover td {
    background-color: rgb(240, 249, 255);
  }
}
