.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Use the full height of the viewport */
  text-align: center;
}

h3 {
  margin: 0; /* Remove default margin to ensure proper centering */
}