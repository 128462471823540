
.newsletter_input {
  align-items: center;
  background-color: var(--light-grey);
  border: none;
  border-radius: 4px;
  display: flex;
  font-size: 1rem;
  height: inherit;
  outline: none;
  padding: 0.5rem;
  width: 15rem;
}

.submit_btn {
  background-color: #46a4dd;
  text-align: center;
  font-size: 0.8rem;
  color: white;
  width: 40%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  transition: background-color 0.3s ease;
}

.category_tabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4rem;
}

.tab {
  padding: 0.2rem 0.4rem;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 0.2rem solid transparent;
  margin-right: 1rem;
  text-transform: uppercase;
  color: #333;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.tab:hover {
  border-color: #cfcfcf;
}

.tab.active {
  border-color: var(--default-blue);
  color: var(--default-blue);
}

.content_item {
  width: calc(33.333% - 20px);
  cursor: pointer;
  max-width: 300px;
  min-width: 250px;
}



@media (max-width: 768px) {
  .newsletter_form {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  .submit_btn {
    width: fit-content !important;
  }

  .content_item {
    width: 100%;
    max-width: 100%;
    min-width: auto;
    margin: 0 auto;
  }
}
