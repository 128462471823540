/* Goal Details Box */
.goal_box {
  font-family: Inter !important;
  width: 416px;
  height: 200px;
}

.goal_box_title {
  font-family: Inter !important;
}

.progress_bar::-webkit-progress-bar {
  border-radius: 18px;
  background-color: #ededed;
}

.progress_bar::-moz-progress-bar {
  border-radius: 18px;
  background-color: var(--default-blue);
}

.progress_bar::-webkit-progress-value {
  border-radius: 18px;
  background-color: var(--default-blue);
}

/* QR Code Box */
.qrcode_box {
  width: 416px;
  height: 611px;
}

/* Edit Campaign */
.form_column {
  flex-basis: calc(50% - 0.5rem);
}

.form_column input,
.form_column select,
.form_row input,
.form_row select {
  color: var(--dark-grey);
}

.form_row select,
.form_row input {
  margin-bottom: 0 !important;
}

.campaign_modal {
  z-index: 2002;
  width: 1150px;
}

.campaign_modal_overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300000000;
}

.sortable_helper {
  z-index: 10000 !important;
}

@media (max-width: 1000px) {
  .campaign_modal {
    width: 80%;
  }
  .goal_container {
    display: none;
  }
}

@media (max-width: 900px) {
  .form_column {
    flex-basis: 100%;
  }

  .form_column input,
  .form_column select,
  .form_row input,
  .form_row select {
    width: 100%;
  }
}

@media (max-width: 625px) {
  .campaign_modal {
    width: 95%;
  }
}

@media (max-width: 530px) {
  .campaign_modal {
    width: 97.5%;
  }

  .form_column input,
  .form_column select,
  .form_row input,
  .form_row select {
    margin: auto;
    width: 90%;
  }
}
