.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal_content {
  background-color: white;
  padding: 20px;
  padding-top: 40px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 500px;
}

.modal_header {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}

.close_btn {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #333;
}

.modal_input {
  display: flex;
  width: 50%;
  font-size: 1rem;
  padding: 10px;
  margin-top: 10px;
}
