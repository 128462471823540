.banner {
  background-color: #d087c8;
  height: 36.4rem;
  margin-bottom: 2.6vw;
  justify-content: center;
  align-items: center;
}

.banner h1 {
  margin-bottom: 2.6vw;
  font-family: Finger Paint;
  font-size: 6rem;
  font-weight: 400;
  line-height: 112.03px;
  letter-spacing: -1.5px;
  width: 1000px;
  height: auto;
}

/* Signup Landing page */
.containers {
  row-gap: 5rem;
  column-gap: 1.8rem;
  justify-content: center;
  align-items: center;
  padding: 0 2.6vw 4vw 2.6vw;
}

.container {
  box-shadow: 0px 4px 40px 5px #0000004d;
  background-color: #ffffff;
  padding: 3rem 2.4rem;
  width: 50%;
  border-radius: 40px;
  font-family: Inter;
  justify-content: center;
  align-items: center;
}

.container h2 {
  font-family: Inter;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 56.02px;
  letter-spacing: -1.5px;
}

.container p {
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.15000000596046448px;
}

/* Actual signup components */
.signup_banner {
  height: 322px;
  background-color: #d087c8;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.signup_banner h1 {
  font-family: Finger Paint;
  font-size: 6rem;
  font-weight: 400;
  line-height: 112.03px;
  letter-spacing: -1.5px;
  width: 1028px;
}

.signup_form {
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.page {
  width: 567px;
}

.progress_bar {
  width: 432px;
  height: 14px;
}

.progress_bar::-webkit-progress-bar {
  border-radius: 18px;
  background-color: #ededed;
}

.progress_bar::-moz-progress-bar {
  border-radius: 18px;
  background-color: var(--default-blue);
}

.progress_bar::-webkit-progress-value {
  border-radius: 18px;
  background-color: var(--default-blue);
}

.form {
  width: 567px;
  justify-content: flex-start;
  text-align: left;
  gap: 1.5rem;
  margin: 1.5rem 0 2rem 0;
}

.name {
  width: 100%;
  gap: 2rem;
}

label {
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.15000000596046448px;
  color: #2c2c2c;
}

input {
  border: 1px solid #939393;
  background: #ffffff;
  width: 100%;
  height: 51px;
  padding: 17px 12px;
  border-radius: 16px;
}

.slider_container {
  margin: 50px 0;
}

#slide-width {
  width: 319px !important;
}

.buttons:before {
  content: none !important;
}

.slide {
  width: 319px !important;
  height: 20rem;
  padding: 48px 28px;
  border-radius: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 40px 10px #00000033;
  margin: 50px 0;
}

.landing_slide {
  height: 250px;
}

.slider_container > .slick-current {
  width: 497px;
  height: 250px !important;
  padding: 52px 53px;
  gap: 10px;
  border-radius: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 40px 4px #d087c8;
}

.slide h2,
.boxes h2 {
  font-family: Finger Paint;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #5a5d5f;
}

.slide h3,
.boxes h3 {
  font-family: Inter;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -1.5px;
  text-align: centert;
  color: #000000;
}

.slide p,
.boxes p {
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
  color: #000000;
}

.selection_container {
  margin: 50px 0;
  width: 586px;
  height: 414px;
  padding: 52px 53px;
  gap: 10px;
  border-radius: 40px;
  box-shadow: 0px 4px 40px 5px #d087c8;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
}

.selection_container h2 {
  width: 100%;
  font-family: Finger Paint;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.2;
  color: #4c4c4c;
}

.selection_container h3 {
  width: 100%;
  font-family: Inter;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -1.5px;
  color: #000000;
}

.selection_container p {
  width: 100%;
  font-family: Inter;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #000000;
}

.success_container {
  margin: 50px 0;
  width: 624px;
  font-family: Finger Paint;
  font-size: 3rem;
  font-weight: 400;
  line-height: 56.02px;
  text-align: center;
  color: #1c1c1c;
}

.success_container h2 {
  margin-bottom: 50px;
}

.buttons {
  width: 567px;
  justify-content: space-between;
}

@media (min-width: 1537px) {
}

@media (max-width: 1536px) {
}
@media (max-width: 1024px) {
  /* Signup Landing page */
  .containers {
    padding-bottom: 5rem;
  }

  .slide {
    width: 300px !important;
  }
}

@media (max-width: 912px) {
  .banner {
    margin-bottom: 3.5vw;
  }

  .banner h1 {
    width: 80%;
    font-size: 4.8rem;
  }

    /* Signup Landing page */
  .container h2 {
    font-size: 2.1rem;
  }
  
  .container p {
    font-size: 1.2rem;
  }


  .signup_banner h1 {
    font-size: 5rem;
    width: 90%;
  }

  .slide {
    width: 280px !important;
  }
}
@media (max-width: 820px) { 
  .banner {
    padding-bottom: 3rem;
    margin-bottom: 5rem;
  }
}

@media (max-width: 768px) { 
  .flex_items {
    flex: 1 1 calc(50% - 4rem); /* Each container will take up 1/3rd of the row minus the gap */
    max-width: calc(50% - 4rem);
  }
  
  .flex-container > .flex_items:nth-child(4),
  .flex-container > .flex_items:nth-child(5) {
    flex: 1 1 calc(50% - 5rem); /* Adjust the flex basis for the 4th and 5th items */
    max-width: calc(50% - 5rem);
  }
  
  .signup_banner h1 {
    font-size: 4.5rem;
  }
}

@media (max-width: 600px) {
  .banner {
    height: auto;
    padding-bottom: 3rem;
    margin-bottom: 5rem;
  }

  .banner h1 {
    font-size: 4.5rem;
    width: 75%;
  }

  /* Signup Landing page */

  .container h2 {
    font-size: 1.8rem;
  }

  /* Other pages */
  .selection_container {
    width: 80%;
  }

  .selection_container h2 {
    font-size: 2.5rem;
  }

  .selection_container h3 {
    font-size: 2.5rem;
  }

  .success_container {
    width: 80%;
  }
  .page {
    width: 90%;
    gap: 1rem;
  }

}

@media (max-width: 540px) { 
  .banner h1 {
    font-size: 4rem;
  }

  .flex_items {
    flex: 1 1 calc(100% - 4rem); /* Each container will take up 1/3rd of the row minus the gap */
    max-width: calc(100% - 4rem);
  }
  
  .flex-container > .flex_items:nth-child(4),
  .flex-container > .flex_items:nth-child(5) {
    flex: 1 1 calc(100% - 5rem); /* Adjust the flex basis for the 4th and 5th items */
    max-width: calc(100% - 5rem);
  }

  .form {
    width: 90%;
  }

  .buttons {
    width: 90%;
  }

  #slide-width {
    width: 270px !important;
  }
  
  .slide {
    width: 270px !important;
  }
}

@media (max-width: 500px) {
  .banner h1 {
    font-size: 3.5rem;
  }

  .signup_banner h1 {
    font-size: 3rem;
    line-height: 1.7;
  }
  .success_container {
    font-size: 2.2rem;
  }

  #slide-width {
    width: 280px !important;
  }

  .slide {
    width: 280px !important;
  }
}

@media (max-width: 430px) {
  .banner h1 {
    width: 75%;
  }

  /* Signup Landing page */
  .container_5 h2 {
    font-size: 2rem;
  }
  
  .container_10 h2 {
    font-size: 2rem;
  }
  
  .container_20 h2 {
    font-size: 2rem;
  }

  .container_5 {
    height: auto;
    width: 100%;
  }

  .container_10 {
    width: 100%;
    height: auto;
  }

  .container_20 {
    height: auto;
    width: 100%;
  }

  .slide {
    width: 210px !important;
  }
  .slide h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 414px) {
  .banner h1 {
    font-size: 2.7rem;
    line-height: 1.5;
  }
  .containers {
    width: 100%;
  }

  .boxes h2 {
    font-size: 1.5rem;
  }

  .boxes h3 {
    font-size: 2rem;
  }
  
  .boxes p {
    font-size: 1.2rem;
  }
}

@media (max-width: 390px) { 
  .selection_container h2 {
    font-size: 2rem;
  }
  
  .selection_container h3 {
    font-size: 2rem;
  }
  
  .selection_container p {
    font-size: 1rem;
  }
}

@media (max-width: 360px) { 
  .banner h1 {
    font-size: 2.8rem;
  }

  .slide {
    width: 150px !important;
  }

  .boxes h2 {
    font-size: 1.2rem;
  }
  
  .boxes p {
    font-size: 1.2rem;
  }
}