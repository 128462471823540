.form_container {
  display: flex;
  flex-direction: column;
  padding-top: 0rem;
}

.form_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.form_column {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 0.5rem);
}

.form_column input,
.form_column select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.file_input {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.submit_button {
  padding: 0.5rem;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.campaign_edit_modal_content {
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Adjust to fit your design */
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.campaign_edit_modal_content > button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  width: 5% !important;
}

.upload_images_label {
  display: block;
  margin-bottom: 0.5rem;
}

.modal_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2000;
}

.modal {
  position: relative;
  padding: 10px;
  background: white;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: auto;
}

.form_title {
  color: var(--default-orange);
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.edit_modal_submit_button {
  margin: 0 auto;
}

.modify_buttons_row {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.modify_buttons_row > button {
  flex: 1;
  width: 1rem;
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 20px;
}

.table_styles_override {
  border-spacing: 0 15px;
}

.table_styles_override tbody tr {
  box-shadow: 0px 4px 4px 0px var(--grey-shadow);
  border-radius: 0.75rem;
}

/* Add a hover effect for table rows */
.table_styles_override tbody tr:hover {
  background-color: var(--light-grey);
}

.table_styles_override tbody tr:hover input {
  background-color: var(--light-grey);
}

.table_styles_override tbody tr:hover td {
  background-color: var(--light-grey);
}

.table thead th {
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
  text-align: center;
}

.table tbody td {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}

/* Add a hover effect for table rows */
.table tbody tr:hover {
  background-color: #f5f5f5;
}

.table tbody tr:hover input {
  background-color: #f5f5f5;
}

.contact_form_container {
  text-align: center;
  max-width: 600px;
  padding: 1rem;
}

.contact_form_container > h1 {
  color: var(--default-orange);
}
.contact_form_container > h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.contact_form_container > form {
  display: flex;
  flex-direction: column;
}