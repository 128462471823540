.partner_feature_page {
  margin: 0;
  padding: 0;
  border: none;
  display: block;
}

.partner_feature {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  margin-top: 100px;
  padding: 48px;
}

.partner_header_container {
  display: flex;
  align-items: center;
}

.partner_header_container > img {
  width: 256px;
  height: 256px;
  object-fit: contain;
}

.partner_description_container {
  flex: 1;
  text-align: left;
  margin-left: 64px;
  display: flex;
  flex-direction: column;
}

.partner_header_container > div > a {
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  margin-bottom: 16px;
}

.social_icon_container {
  display: flex;
  gap: 16px;
}
.social_icon_container a {
  text-decoration: none;
  color: var(--dark-grey);
}
.social_icon_container svg {
  margin: 0;
}

.partner_products_scrollable {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.2rem;
  padding: 0.2rem;
}

.banner_products {
  display: flex;
  width: 100%;
  margin: 2rem auto;
  align-items: center;
  justify-content: center;
}

.partner_product_card {
  max-width: 18rem;
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 8px;
  border: 1px solid var(--light-grey);
  border-radius: 16px;
  box-shadow: 0 0 4px darkgrey;
  text-align: left;
  overflow: hidden;
  padding: 16px;
  color: var(--default-blue);
}

.partner_product_card_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.partner_product_card > img {
  height: 10rem;
  width: fit-content;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  object-fit: contain;
  border-radius: inherit;
  margin: 0 auto;
}
.partner_product_card > h3 {
  font-family: "Finger Paint", cursive;
  width: 100%;
  margin: 0;
  color: var(--default-blue);
}
.partner_product_card > p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  flex: 1;
}

.partner_product_card:hover {
  box-shadow: 0 0 8px var(--light-grey);
}

.partner_feature_page .website_link a {
  color: inherit;
  text-decoration: none;
}

.partner_feature_page .website_link a:hover {
  color: orange;
  text-decoration: underline;
}


@media (max-width: 1000px) {
  .partner_header_container {
    flex-direction: column;
  }
  .partner_description_container {
    margin: 0;
    margin-right: auto;
    padding: 0 32px;
  }
}

@media (max-width: 768px) {
  .partner_products_scrollable {
    grid-template-columns: 1fr;
  }
}