.subscription_btn_container {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.subscription_btn_container > button {
  padding: 0.5rem;
  font-size: 0.9rem;
  display: flex;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  height: 2rem;
}

.btn {
  display: flex;
  justify-content: center;
  width: 10rem;
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}

.card_btn {
  display: flex;
  justify-content: center;
  width: 5rem;
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto 0.5rem;
}

.success {
  color: rgb(75,181,67);
}