.header {
  display: none;
  width: 90%;
  margin: auto;
  margin-bottom: 2rem;
}

.header button {
  width: 100%;
  background-color: #d8d8d8;
  color: #7a7a7a;
  font-size: 20px;
}

.header svg {
  margin-top: 5px;
  margin-right: 5px;
}

.dropdown_content {
  position: absolute;
  top: 100%; /* Position the dropdown just below the user icon */
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 100%;
}

.header:hover .dropdown_content {
  display: block;
}

.dropdown_content button {
  padding: 5px;
  text-decoration: none;
  width: 100%;
  background-color: #f3f3f3;
  text-align: left;
}

.dropdown_content button:hover {
  color: #ffffff;
  background-color: #00a4bd !important ;
}

@media screen and (max-width: 853px) {
  .header {
    display: inline-block;
    position: relative;
  }
}
