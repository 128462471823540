.cards_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.pet_card {
  display: flex;
  flex-direction: column;
  width: 20rem;
  min-height: 20rem;
  max-height: 32rem;
  box-shadow: 0 0 4px darkgrey;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
}

.pet_card:hover {
  box-shadow: 0 0 8px darkgrey;
}

.pet_card img {
  width: 100%;
  height: 12rem;
  display: block;
  object-fit: cover;
}
.card_content {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card_content h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: var(--default-orange);
}

.card_content .date,
.card_content .read_time {
  font-size: 0.9em;
  color: #666;
  margin: 0;
}

.card_content .description {
  font-size: 0.95em;
  margin-top: 10px;
  text-align: left !important;
}

.read_more {
  margin-top: auto;
  padding: 10px 15px;
  background-color: var(--default-blue);
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.read_more:hover {
  background-color: var(--dark-blue);
}

.reading_time {
  color: var(--dark-grey);
  font-size: 0.9rem;
}

.card_subheading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
}
