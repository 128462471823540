.form_column {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 0.5rem);
}

.form_row {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}

.form_column textarea {
  padding: 0.5rem;
  border: 1px solid #ddd;
  min-height: 8rem !important;
  color: var(--unselected-grey);
  border-radius: 4px;
  flex: 1;
  margin-bottom: 0.4rem;
  resize: none;
  overflow: auto;
}

.parent_div {
  display: flex;
  position: relative;
  flex-grow: 1;
}

.asterisk_div {
  position: absolute;
  left: 2.5px;
  color: red;
}

.form_column input,
.form_row input {
  padding: 0.5rem;
  border: 1px solid #939393;
  color: black;
  border-radius: 12px;
  margin-bottom: 0.4rem;
  flex: 1;
}

.form_column select,
.form_row select {
  padding: 0.5rem;
  border: 1px solid #939393;
  color: var(--unselected-grey);
  border-radius: 12px;
  margin-bottom: 0.4rem;
  flex: 1;
}

.sortable_helper {
  z-index: 10000 !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.graphic_form_row {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 0.7rem;
}

.graphic_form_row > a {
  margin-left: inherit;
}

.file_input {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.close_button {
  position: absolute;
  margin-right: 1rem;
  max-width: 1rem;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.modal_content {
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Adjust to fit your design */
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.upload_images_label {
  display: block;
  margin-bottom: 0.5rem;
}

.modal_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.form_title {
  color: var(--default-orange);
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.campaign_modal {
  position: relative;
  padding: 20px;
  background: white;
  z-index: 2000;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: auto;
}

.campaign_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: baseline;
  justify-content: center;
  z-index: 2000;
  overflow: auto;
  padding: 50px;
}

.form_label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .campaign_modal {
    width: 100%;
  }

  .form_row {
    flex-direction: column;
  }

  .form_row input {
    width: 100%;
  }
}
