.campaign_title_link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: var(--default-orange) !important;
  font-size: x-large !important;
}

.campaign_search_card_container {
  max-width: 70rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin: 0 auto;
  padding: 0 5rem;
  margin-top: 2rem;
  justify-content: left;
}

.campaign_search_card {
  max-width: 18rem;
  width: 100%;
  flex: 0 0 22.5rem;
  flex-direction: column;
  border: 0px var(--unselected-grey) solid;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px darkgrey;
  display: flex;
  overflow: hidden;
  padding: 1rem;
  gap: 0.75rem;
  height: 26rem;
  text-align: left;
  user-select: none;
  background-color: white;
  color: black;
  cursor: pointer;
}
.campaign_search_card:hover {
  box-shadow: 0 0 8px darkgrey;
}
.campaign_search_card_div {
  flex: 1;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.campaign_search_card > div > h3 {
  font-family: "Finger Paint", cursive;
  width: 100%;
  margin: 0;
  color: var(--default-blue);
}
.campaign_search_card > div > p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  flex: 1;
}
.graphic_blurred {
  filter: blur(10px);
}
.campaign_search_card_footer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: black;
  font-size: xx-large;
  background-color: var(--default-blue);
  width: 9rem;
  height: 2.5rem;
  margin: auto;
  border-radius: 1.5rem;
}
.campaign_search_card_footer > p {
  margin: 0;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
}
.campaign_search_card_footer > svg {
  margin-left: auto;
  width: 1.4rem;
}

.campaign_search_card > .campaign_search_card_header {
  flex: 0;
  display: flex;
  flex-direction: row;
}
.campaign_search_card > .campaign_search_card_header > svg {
  margin-left: auto;
  color: var(--default-orange);
  cursor: pointer;
}

.campaign_search_card_img {
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .campaign_search_card {
    min-height: 27rem;
  }
}
