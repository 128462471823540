.partner_search {
  max-width: 1700px;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  max-width: 90rem;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 8rem;
  padding-bottom: 8rem;
  box-shadow: 0 0 5px lightgrey;
}

.partner_search_card_container {
  max-width: 70rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin: 0 auto;
  padding: 0 5rem;
  margin-top: 2rem;
  justify-content: left;
}

@media ((min-width: 656px) and (max-width: 1151px)) {
  .partnersearch_panel_search_card_container {
    justify-content: center;
  }
}

.partner_search_card {
  max-width: 18rem;
  min-width: 18rem;
  width: 100%;
  flex: 0 0 22.5rem;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 0 4px darkgrey;
  display: flex;
  overflow: hidden;
  padding: 1.25rem;
  gap: 0.75rem;
  height: 25rem;
  text-align: left;
  user-select: none;
  background-color: white;
  color: black;
}
.partner_search_card:hover {
  box-shadow: 0 0 8px darkgrey;
}
.partner_search_card > div {
  flex: 1;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.partner_search_card > div > h3 {
  font-family: "Finger Paint", cursive;
  width: 100%;
  margin: 0;
  color: var(--default-blue);
}
.partner_search_card > div > p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  flex: 1;
}
.partner_search_card .link_icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: black;
  font-size: xx-large;
}
.partner_search_card .link_icon > p {
  margin: 0;
  color: var(--default-orange);
  font-size: 1.25rem;
}
.partner_search_card .link_icon > svg {
  margin-left: auto;
  width: 1.4rem;
  color: var(--default-orange);
}

.partner_search_card > .partner_search_card_header {
  flex: 0;
  display: flex;
  flex-direction: row;
}
.partner_search_card > .partner_search_card_header > svg {
  margin-left: auto;
  color: var(--default-orange);
  cursor: pointer;
}

.partner_search_card img {
  width: 100%;
  height: 8rem;
  margin: 0 auto;
  object-fit: contain;
  overflow: hidden;
}

/* Filter panel stuff */
.search_panel {
  max-width: 45rem;
  width: 100%;
  display: flex;
  margin: 0 auto;
  border: 1px var(--unselected-grey) solid;
  border-radius: 2.25rem;
  overflow: hidden;
  height: 2.25rem;
  align-items: center;
  margin-top: 1rem;
}
.search_panel > svg {
  cursor: default;
  color: var(--unselected-grey);
  margin: 0 0.5rem;
}
.search_panel > input {
  flex: 1;
  border: none;
  outline: none;
}

/* Mobile stuff */
@media (max-width: 768px) {
  .partner_search_card_container {
    padding: 0;
    justify-content: center;
  }
  .partner_search_card_flex_horizontal > .partner_search_card_flex_vertical {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    text-align: right;
  }
  .partner_search_card_flex_horizontal
    > .partner_search_card_flex_vertical
    > p {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
  }
}
