.signup_page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.acc_submit_btn {
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  background-color: var(--default-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 30%;
  justify-content: center;
  align-items: center;
}

.signup_card {
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
}

.signup_card input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  width: 94.5%;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.signup_card select {
  margin-bottom: 1rem;
  padding: 0.5rem;
  color: grey;
  width: 95%;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.password_error {
  color: red;
  margin-top: 10px;
  white-space: pre-wrap;
}

.login_prompt {
  margin-top: 1rem; /* Add space above the prompt */
  text-align: center;
}

.login_prompt a {
  color: #007bff;
  text-decoration: none;
}

.login_prompt a:hover {
  text-decoration: underline;
}
